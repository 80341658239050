import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { getDefaultNavigation, setSearchText, toggleNavigation } from 'services/navigationv2';
import { ChangeButton, ChangeButtonWrapper, FolderParentPageContainer, StyledInput } from './styles';
import { getPageId } from 'services/app';

const FolderParentPageField = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const defaultNavigation = useSelector(getDefaultNavigation);
  const currentPageId = useSelector(getPageId);

  const parentNavigation = React.useMemo(() => {
    for (const parent of defaultNavigation.parents) {
      const foundChild = parent.children.find((node) => node.id === currentPageId);
      if (foundChild) return parent;
    }
  }, [defaultNavigation.parents, currentPageId]);
  const parentName = parentNavigation ? parentNavigation.name : t('ADMIN_LABEL_NONE');

  const toggleToSiteNavigation = () => {
    dispatch(toggleNavigation(true));
    dispatch(setSearchText(parentName.trim()));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') toggleToSiteNavigation();
  };

  return (
    <FolderParentPageContainer>
      <StyledInput
        descriptionKey={'ADMIN_LABEL_FOLDER_OR_PARENT_PAGE_DESCRIPTION'}
        inputTestId="folderParentPageInput"
        labelKey="ADMIN_LABEL_FOLDER_OR_PARENT_PAGE_TAG"
        padding="0"
        value={parentName}
        hasButton={true}
        onKeyDown={handleKeyDown}
      >
        <ChangeButtonWrapper>
          <ChangeButton onClick={toggleToSiteNavigation}>
            {t('ACCESS_CONTROL_CHANGE_GATE')}
          </ChangeButton>
        </ChangeButtonWrapper>
      </StyledInput>
    </FolderParentPageContainer>
  );
};
export default FolderParentPageField;


