import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ADMIN_ERROR_MODAL_ROOT } from 'global-ids';
import AdminModal from 'components/admin2/ui/AdminModal';
import MediaLibrary from 'components/admin2/MediaLibrary';
import { ASC_KEY, DESC_KEY, NONE_KEY } from 'components/admin2/MediaLibrary/constants';
import { SERVICE_BASE_URL } from 'config';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';
import { getRefreshKey } from 'services/admin';
import styled from 'styled-components';
import hash from 'json-stable-stringify';
import { useAjax } from 'hooks';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

const StyledMediaLibrary = styled(MediaLibrary)`
  width: 100%;
  height: 100%;
  button {
    padding: 0px 10px;
    ${ADMIN_TEXT_BODY_XS_REGULAR}
  }
  button:not(:first-child) {
    margin-left: 10px;
  }
`;

const BASE_URL = SERVICE_BASE_URL;
const SORT_MAP = {
  [ASC_KEY]: 'asc',
  [DESC_KEY]: 'desc',
  [NONE_KEY]: null,
};

const propTypes = {
  buttonProps: PropTypes.oneOfType([
    PropTypes.shape({
      labelKey: PropTypes.string,
      onClick: PropTypes.func,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        labelKey: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
  ]),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableEditing: PropTypes.bool,
  disableKeyClick: PropTypes.bool,
  disableSelecting: PropTypes.bool,
  fetchObject: PropTypes.shape({
    endpoint: PropTypes.string,
    method: PropTypes.oneOf(['GET']),
    service: PropTypes.string,
  }).isRequired,
  forceRefresh: PropTypes.bool,
  formatData: PropTypes.func,
  helperText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onEditItem: PropTypes.func,
  onKeyClick: PropTypes.func,
  onSelectItem: PropTypes.func.isRequired,
  searchPlaceholderKey: PropTypes.string,
  titleKey: PropTypes.string.isRequired,
};
const defaultProps = {
  buttonProps: [],
  disableEditing: false,
  disableSelecting: false,
  forceRefresh: false,
  helperText: '',
  searchPlaceholderKey: '',
};

const LibraryModalSubscription = (props) => {
  const defaultParams = {
    limit: 150,
    sortBy: 'created',
    sortDirection: 'desc',
  };
  const [loaded, setLoaded] = useState(false);
  const [params, setParams] = useState(defaultParams);
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const refreshKey = useSelector(getRefreshKey);

  const {
    buttonProps,
    columns,
    disableEditing,
    disableKeyClick,
    disableSelecting,
    fetchObject,
    forceRefresh,
    formatData,
    helperText,
    onClose,
    onEditItem,
    onKeyClick,
    onSelectItem,
    searchPlaceholderKey,
    titleKey,
  } = props;

  const {
    method = 'GET',
    service,
  } = fetchObject;

  const headers = {
    Authorization: `Bearer ${primaryToken}`,
    'x-maestro-client-id': siteId,
  };

  const ajaxOptions = {
    headers,
    method,
    params,
    refreshKey: `${refreshKey}${forceRefresh}`,
    url: `${BASE_URL}${service}`,
  };

  const { data } = useAjax(ajaxOptions);
  const formattedData = useMemo(() => {
    if (!data) {
      return null;
    }
    setLoaded(true);
    if (formatData) {
      return formatData(data);
    }
    return data;
  }, [hash(data)]);

  const setQueryParams = (newParams) => {
    setLoaded(false);
    if (newParams.search?.length === 0 && params.search) {
      const { search, ...restParams } = params;
      setParams({ ...restParams });
    } else {
      setParams({ ...params, ...newParams });
    }
  };

  const handleSort = (sortObject = {}) => {
    const { sort: sortKey, title: sortBy } = sortObject;
    const sortValue = SORT_MAP[sortKey];
    const sortParams = sortValue ? {
      sortBy: sortObject.accessor || sortBy,
      sortDirection: sortValue,
    } : {};
    setQueryParams(sortParams);
  };

  const handleSearch = (searchText) => {
    setQueryParams({ search: searchText });
  };

  const findInitialSort = newColumns => newColumns.find(col => Boolean(col.sort));

  useEffect(() => {
    handleSort(findInitialSort(columns));
  }, []);

  return (
    <AdminModal
      id={ADMIN_ERROR_MODAL_ROOT}
      maxHeight="700px"
      maxWidth="1000px"
      onClose={onClose}
      overlapAdminbar
    >
      <StyledMediaLibrary
        buttonProps={buttonProps}
        columns={columns}
        disableEditing={disableEditing}
        disableKeyClick={disableKeyClick}
        disableSelecting={disableSelecting}
        helperText={helperText}
        list={formattedData}
        loaded={loaded}
        onEditItem={onEditItem}
        onKeyClick={onKeyClick}
        onSearch={handleSearch}
        onSelectItem={onSelectItem}
        onSort={handleSort}
        searchPlaceholderKey={searchPlaceholderKey}
        setLoading={() => setLoaded(false)}
        testIdSearcher="ticketAndSubscriptionSearchInput"
        testIdTable="ticketAndSubscriptionTable"
        titleKey={titleKey}
      />
    </AdminModal>
  );
};

LibraryModalSubscription.propTypes = propTypes;
LibraryModalSubscription.defaultProps = defaultProps;
export default LibraryModalSubscription;
