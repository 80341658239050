import styled, { css, DefaultTheme, IStyledProps, ThemedStyledProps } from 'styled-components';
import AdminSearchInput from 'components/admin2/ui/SearchInput';
import UILabel from 'components/admin2/ui/Label';
import { StyledLoader } from 'components/admin2/ui/SearchInput/styles';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_5,
  ADMIN_SURFACE_3,
  MAX_DROPDOWN_HEIGHT,
  ADMIN_TEXT_100,
  ACCENT_PRIMARY,
  TEXT_100,
  BODY_FONT_FAMILY,
  SURFACE_3,
  ADMIN_FONT_FAMILY,
  ADMIN_TEXT_300,
  TEXT_300,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
  ADMIN_TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import {
  ADMIN_SCROLL_BAR,
  SCROLL_BAR,
  TEXT_ELLIPSIS,
} from 'style/mixins';

import { lightenDarken } from 'colors';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_XS_MEDIUM, TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';

export type StyleProps = ThemedStyledProps<{
  autoFocus?: boolean;
  compact?: boolean;
  compactDropdown?: boolean;
  dropUp?: boolean;
  isActive?: boolean;
  isAdmin?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
  isSearchable?: boolean;
  isSelected?: boolean;
  isVisible?: boolean;
}, DefaultTheme>;

const optionBackground = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_SURFACE_3 : SURFACE_3(props);
const optionTextColor = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_TEXT_100 : TEXT_100(props);
const hoverBackground = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_SURFACE_5 : lightenDarken({
  hex: SURFACE_3(props),
  percentage: 0.3,
});
const activeTextColor = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_TEXT_CONTRAST_ON_ACCENT_PRIMARY : TEXT_CONTRAST_ON_ACCENT_PRIMARY(props);
export const activeColor = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props);
const H6 = ({ isAdmin }: StyleProps) => isAdmin ? ADMIN_TEXT_BODY_XS_REGULAR : TEXT_BODY_XS_REGULAR;
const textSmall = ({ isAdmin }: StyleProps) => isAdmin ? ADMIN_TEXT_BODY_XS_REGULAR : TEXT_BODY_XS_REGULAR;
const placeholderColor = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_TEXT_300(props) : TEXT_300(props);
const scrollbar = ({ isAdmin }: StyleProps) => isAdmin ? ADMIN_SCROLL_BAR : SCROLL_BAR;
const containerActiveBackground = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_SURFACE_3 : SURFACE_3(props);
const containerBackground = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_SURFACE_3 : SURFACE_3(props);
const containerColor = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_TEXT_100 : TEXT_100(props);
export const textColorContent = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_TEXT_100 : TEXT_100(props);
const contentFont = ({ isAdmin, ...props }: StyleProps) => isAdmin ? ADMIN_FONT_FAMILY(props) : BODY_FONT_FAMILY(props);
const height = ({ compact }: StyleProps) => compact ? '22px' : '56px';

export const OptionWrapper = styled.div<StyleProps>`
  &:hover {
    background: ${hoverBackground};
  }
  ${({ isActive, ...props }) => isActive && css`
    background: ${activeTextColor(props)};
    color: ${activeColor(props)};
  `}
  ${({ isSelected }) => isSelected && css`
    background: ${hoverBackground};
  `}
`;

export const Container = styled.div<StyleProps>`
  ${H6}
  position: relative;
  background: ${({ isActive, ...props }) => isActive ? containerActiveBackground(props) : containerBackground(props)};
  color: ${containerColor};
  border-radius: 4px;
  ${({ isSearchable }) => isSearchable && `
    cursor: text;
  `}
  ${({ isDisabled }) => isDisabled && `
    opacity: 0.3;
    & > button {
      cursor: default;
    }
  `}
`;

export const Button = styled.div<StyleProps>`
  min-width: 60px;
  min-height: ${height};
  ${({ compactDropdown }) => compactDropdown && css`height: ${height};`}
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div<StyleProps>`
  ${textSmall}
  color: ${({ isSelected }) => isSelected ? textColorContent : placeholderColor};
  padding: 12px;
`;

export const DropDownMenu = styled.div<StyleProps & { dropdownMaxHeight?: string }>`
  ${scrollbar}
  text-align: left;
  max-height: ${({ dropdownMaxHeight }) => dropdownMaxHeight || MAX_DROPDOWN_HEIGHT};
  width: 100%;
  position: absolute;
  left: 0;
  ${({ dropUp }) => dropUp ? 'bottom' : 'top'}: 100%;
  z-index: 300;
  background-color: ${optionBackground};
  color: ${optionTextColor};
  box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2);
`;

export const OptionContainer = styled.div`
  text-align: left;
`;

export const SearchInput = styled(AdminSearchInput) <StyleProps>`
  &&, & input {
    font-family: ${contentFont} !important;
  }
  position: absolute;
  flex: 1;
  padding-right: 25px;
  height: 100% !important;
  background: inherit;
  & ${StyledLoader} {
    right: 40px;
  }
  & input {
    padding: 12px;
    & + div {
      margin-left: 12px;
    }
  }
`;

export const BASE_LABEL = css`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: ${textColorContent};
  font-family: ${contentFont};
  cursor: pointer;
  height: 27px;
`;

export const Label = styled.div<StyleProps>`
  ${BASE_LABEL};
  ${({ isActive, ...props }) => isActive && css`
    color: ${activeTextColor(props)};
    background: ${activeColor(props)};
  `}
`;

export const SITE_NAVIGATION_TEXT = css`
  ${TEXT_ELLIPSIS};
  ${ADMIN_TEXT_LABEL_M_MEDIUM};
  font-family: ${ADMIN_FONT_FAMILY};
  color: ${ADMIN_TEXT_100};
`;

export const SiteNavigationText = styled.span`
  ${SITE_NAVIGATION_TEXT};
`;

export const LabelText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionName = styled.div`
  padding: 10px 16px;
`;

export const KeyListener = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1000;
`;

export const EmptyOption = styled.div`
  height: 27px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  display: block;
  text-align: center;
  line-height: 6px;
`;

export const LabelWithoutPadding = styled(UILabel).attrs({ padding: '0 0 5px' })``;
