import TextInput from 'components/admin2/TextInput';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_3 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const FolderParentPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const StyledInput = styled(TextInput)`
  input[data-testid='${props => props.inputTestId}'] {
    padding: 4px 0px 4px 16px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  width: 100%;
`;

export const ChangeButtonWrapper = styled.div`
  background: ${ADMIN_SURFACE_3};
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  padding: 4px 16px 4px 0px;
`;

export const ChangeButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  text-decoration: none;

  color: ${ADMIN_ACCENT_PRIMARY};
  ${ADMIN_TEXT_BODY_XS_REGULAR}
`;
